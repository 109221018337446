import { render, staticRenderFns } from "./kowledgePointsVideocopy.vue?vue&type=template&id=104603cf&scoped=true&"
import script from "./kowledgePointsVideocopy.vue?vue&type=script&lang=js&"
export * from "./kowledgePointsVideocopy.vue?vue&type=script&lang=js&"
import style0 from "./kowledgePointsVideocopy.vue?vue&type=style&index=0&id=104603cf&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "104603cf",
  null
  
)

export default component.exports