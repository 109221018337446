<!-- /*测试用页面 **/ -->
<template>
  <div class="mainvideo">
    <div id="J_prismPlayer"></div>
  </div>
</template>

<script>
import { playAuth } from '@/api/know'
export default {
  props: ['wzUrl'],
  data() {
    return {
      timer: null,
      player: null,
      currentVideo: {},
      firstPlayFlag: true,

      videoVisible: true,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.aliplayer()
    })
  },
  methods: {
    videoClose() {
      if (this.player) {
        clearTimeout(this.timer)
        this.player.dispose()
      } else {
        clearInterval(this.timer)
        $.DW.destroy()
      }
      ; (this.timer = null),
        (this.player = null),
        (this.currentVideo = {}),
        (this.firstPlayFlag = true),
        this.$emit('videoClose')
    },

    // 阿里初始化
    async aliplayer() {

      let _this = this
      // 阿里录播
      this.player = new Aliplayer(
        {
          id: 'J_prismPlayer',
          source: this.wzUrl,
          isLive: true,
        },
        function (player) {
          player.seek(_this.currentVideo.playTime)
        }
      )

      this.$nextTick(() => {
        this.player.on('timeupdate', () => {
          this.timeupdate()
        })
      })
    },
    // 限制播放时间段
    timeupdate() {
      let current = this.player.getCurrentTime()
      if (this.timer) return

      this.timer = setTimeout(() => {
        if (current > this.currentVideo.end) {
          this.player.pause()
          this.player.seek(this.currentVideo.playTime)
        } else if (current < this.currentVideo.playTime) {
          this.player.seek(this.currentVideo.playTime)
        }
        clearTimeout(this.timer)
        this.timer = null
      }, 1000)
    },
  },
}
</script>

<style lang="less" scoped>
.popup_box {
  // height: 600px;
  padding-top: 20px;

  #playbackPlayer {
    width: 50%;
    margin: auto;
  }

  #playbackPanel {
    height: 100%;
  }
}

.mainvideo {
  width: calc(80% - 25px) !important;
  height: 100%;
}

#J_prismPlayer {
  height: 100% !important;
}</style>
