<template>
  <div class="bigBox">
    <div class="share_box">
      <div class="share_btn" @click="clickShare">
        <div>分</div>
        <div>享</div>
      </div>
      <div v-show="shareShow" class="share">
        <img class="QRCode" :src="QRCodeUrl" alt="" />
        <div class="PCtext">{{ PCtext }}</div>
        <el-button type="text" @click="copyUrl">复制链接</el-button>
      </div>
    </div>
    <div class="videoBar" :class="courseDetail && courseDetail.state == 2 ? 'aliVideoBar' : ''">
      <div v-if="courseDetail && courseDetail.state == 2" class="videoBox"
        :class="courseDetail && courseDetail.state == 2 ? 'videoWrap' : ''">
        <div class="leftVideo">
          <div class="titleBox">
            <div class="tag tagBack">回放中</div>
            <!-- <div class="tag tagLive">直播中</div>
                        <div class="tag tagNot">未开始</div> -->
            <div class="title">{{ courseDetail.name }}</div>
            <div class="time">直播时间：{{ courseDetail.zbBeginTime }}</div>
          </div>
          <div class="video">
            <div id="vzanvideo" />
          </div>
        </div>
        <div class="rightBox">
          <div class="topTitle">
            <i class="iconfont icon-rementuijian">推荐直播</i>
          </div>
          <div class="contentList">
            <div v-for="item in recommendedLive" :key="item.id" class="contentItem" @click="toDetail(item)">
              <img class="itemImg" :src="item.pcPictureUrl" />
              <div class="contentTitleBox">
                <div class="contentTitle">{{ item.name }}</div>
                <div class="timeItem">开播时间：{{ item.zbBeginTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 直播 -->
      <div v-if="courseDetail &&
        (courseDetail.state == 0 || courseDetail.state == 1) &&
        courseDetail.wzUrl
        " class="mainvideo">
        <div id="J_prismPlayer" />
      </div>
      <!-- <kowledgePointsVideocopy
        v-if="courseDetail && (courseDetail.state == 0 || courseDetail.state == 1) && courseDetail.wzUrl"
        :wzUrl="courseDetail.wzUrl"
      >
      </kowledgePointsVideocopy> -->
      <!-- <video
        :controls="true"
        v-if="courseDetail && courseDetail.state == 2 && courseDetail.wzUrl"
        controlslist="nodownload"
        autoplay="autoplay"
        :src="courseDetail.wzUrl"
      ></video> -->
      <!-- <iframe
        allowfullscreen
        v-if="courseDetail && courseDetail.state == 1 && courseDetail.wzUrl"
        class="videoBox weizan"
        :src="courseDetail.wzUrl"
        scrolling="no"
      ></iframe> -->
      <!-- 回放 -->
      <!-- <iframe
        allowfullscreen
        v-if="
          courseDetail &&
          courseDetail.state == 2 &&
          courseDetail.playbackUrl &&
          courseDetail.aliPlaybackUrl == '2'
        "
        id="iframes"
        class="videoBox weizan weizans"
        :src="courseDetail.wzUrl"
        scrolling="no"
      ></iframe> -->
      <!-- 未开始 -->
      <!-- <iframe
        allowfullscreen
        v-if="courseDetail && courseDetail.state == 0 && courseDetail.wzUrl"
        class="videoBox weizan"
        :src="courseDetail.wzUrl"
        scrolling="no"
      ></iframe>
      聊天 -->
      <div v-if="courseDetail && courseDetail.state != 2" class="NIM_wrap">
        <div class="title">讨论区</div>
        <div id="msgBlock" class="information">
          <div v-for="(item, index) in list" :key="index" class="white">
            <div class="wrap" :class="item.bizFromId == userInfo.wxStuId ? 'dis-end' : 'dis-start'
              ">
              <div class="formNick">{{ item.bizFromName }}</div>
              <div class="text">{{ item.text }}</div>
            </div>
            <div style="clear: both" />
          </div>
        </div>
        <div v-if="imQunId" class="barrageInput">
          <el-input v-model="barrage" class="input" placeholder="请输入" onkeydown="if (event.keyCode===13){return false;}"
            @keyup.enter.native="sendMessage" />
          <!-- <emoji class="mt-10" @isOk="isOk" :ifIndex="true" /> -->
          <el-button size="mini" type="primary" class="sendBtn" @click="sendMessage">发送</el-button>
        </div>
        <div v-else class="barrageInput">
          <el-input v-model="barrage" class="input" placeholder="暂时无法发言" disabled />
        </div>
      </div>
    </div>
    <div class="recommend">
      <div class="recommendText">相关推荐</div>
      <div class="itemBox">
        <div v-for="item in recommendList" :key="item.id" @click="toDetail(item)">
          <div v-if="item.isShow == 1" class="recommendItem">
            <img class="imgItem" :src="item.pcPictureUrl" />
            <div class="textItem">
              <div class="courseName twoEllipsis">{{ item.name }}</div>
              <div class="playNum">
                <img class="symbol" src="@/assets/img/Home/Frame.png" />
                <span class="num">{{ item.clicks ? item.clicks : 0 }}次播放</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Know } from '@/api/know.js'
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
const know = new Know()
import emojiList from '@/assets/json/emoji.json'
import emoji from '@/components/emoji.vue'
import kowledgePointsVideocopy from './kowledgePointsVideocopy.vue'
const NIM = require('@/assets/js/NIM_Web_SDK_v8.9.0.js')
import {
  joinGroups,
  getGroupChatLog,
  queryGroupMsg,
  sendMessage,
} from '@/api/home'
export default {
  components: {
    emoji,
    kowledgePointsVideocopy,
  },
  data() {
    return {
      textVal: '',
      id: null, // 公开课主键ID
      courseDetail: null,
      recommendList: [],
      recommendedLive: [],
      userInfo: null,
      zbStartTime: null,
      massageList: {}, // 消息列表
      imQunId: null,
      barrage: '', // 输入框
      inputInfo: false,
      massage: [],
      emojiList: [], // 表情列表
      token: '',
      req: '',
      items: {},
      list: [],
      im_websocket: '',
      ws_server_url: '',
      im_time_interval: '',
      count: 0,
      stuId: '', // 观看学生用户id
      tenantId: '', // 机构id
      userId: '', // 邀请人用户id'
      status: '', // 1 学生分享  2老师分享 0 主动进去
      time: 0,
      timer: null,
      timers: null,
      shareShow: false,
      QRCodeUrl: '',
      PCtext: '',
      test1: null,
      player: null,
      currentVideo: {},
      firstPlayFlag: true,
      wssresult: '',
    }
  },
  async created() {
    const date = new Date()
    this.zbStartTime = date.toLocaleDateString()
    const obj = {
      accessKeyId: 'JXYqi1xr89ekrnly17sknari',
      accessKeySecret: 'w4fqam7qpjv5f8406e369njcvfmiga',
    }
    const jsonStr = JSON.stringify(obj)

    this.wssresult = btoa(jsonStr)
    this.userInfo = await getInfo()
    console.log(this.userInfo, ';this.userInfo');

    this.ws_server_url = `wss://gateway.juxue.cloud/imserver/ws/${this.userInfo.wxStuId}/${this.wssresult}`

    this.id = await this.$route.query.id
    this.imQunId = await this.$route.query.imQunId
    console.log(this.items, 'this.items')
    // if (this.userInfo) {
    //   this.getDetail()
    //   this.getPublicityClassRecommend()
    //   this.liveToday()

    // };
    know.updatePublicityClassClicks(this.id)
  },
  async mounted() {
    this.userInfo = await getInfo()

    if (this.userInfo) {
      const date = new Date()
      this.zbStartTime = date.toLocaleDateString()
      this.stuId = this.userInfo.id
      this.tenantId = this.userInfo.tenantId
      this.userId = this.$route.query.userId
      this.status = this.$route.query.status
      this.id = this.$route.query.id
      // this.addStudentRecord()
      // this.test1 = setInterval(() => {
      this.getDetail()
      // }, 5000)
      this.getPublicityClassRecommend()
      this.liveToday()
      this.getQRCode()
    } else {
      Vue.prototype.goLoginView(true)
    }
    /* this.getDetail();
    this.getPublicityClassRecommend();
    this.liveToday(); */
    this.coursePublicPvSave()
    this.emojiList = emojiList
  },
  /* beforeDestroy() {
    this.addStudentRecord()
  }, */
  destroyed() {
    // alert(2211)

    // window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },

  methods: {
    beforeunloadHandler(e) {
      // alert("刷新或关闭");

      this.addStudentRecord()
    },
    clickShare() {
      this.shareShow = !this.shareShow
    },
    closeShare() {
      this.shareShow = false
    },
    onbeforeunload() {

      const _this = this
      window.onbeforeunload = async function (e) {
        if (_this.$route.name == '公开课详情') {
          await _this.addStudentRecord()
          clearTimeout(this.timer)
          this.timer = null
          this.time = 0
          e = e || window.event
          // 兼容IE8和Firefox 4之前的版本
          if (e) {
            e.returnValue = '关闭提示1111'
          }
          // alert(2211)/
          // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
          return '关闭提示222'
        } else {
          // alert(22)
          window.onbeforeunload = null
        }
      }
    },
    /* 公开课详情 */
    getDetail() {
      const data = {
        id: this.id,
      }
      know
        .getPublicityClassDetail(data)
        .then((res) => {
          this.imQunId = res.msg.imQunId
          this.im_connect()

          this.joinGroup()
          /* this.imQunId = res.msg.imQunId; */
          res.msg.endTime = new Date(res.msg.zbFinishTime).getTime()
          this.courseDetail = res.msg

          if (res.msg.state == 1) {
            this.onbeforeunload()

            // this.addStudentRecord()
            // this.getTime()
            // clearInterval(this.test1)

            this.$nextTick(() => {
              this.aliplayer(res.msg)
            })
            this.addStudentRecordtime()
          }

          if (res.msg.state == 2) {
            this.plaer()
          }
        })
        .then(() => {
          if (!this.imQunId) return
        })
    },
    addStudentRecordtime() {
      this.test1 = setInterval(() => {
        this.addStudentRecord()
      }, 5000)
    },
    videoClose() {
      if (this.player) {
        clearTimeout(this.timers)
        this.player.dispose()
      } else {
        clearInterval(this.timers)
        $.DW.destroy()
      }
      ; (this.timer = null),
        (this.player = null),
        (this.currentVideo = {}),
        (this.firstPlayFlag = true),
        this.$emit('videoClose')
    },

    // 阿里初始化
    async aliplayer(item) {
      console.log(item.wzUrl, 'wzUrlwzUrl')
      const _this = this
      // 阿里录播
      this.player = new Aliplayer(
        {
          id: 'J_prismPlayer',
          source: item.wzUrl,
          isLive: true,
        },
        function (player) {
          player.seek(_this.currentVideo.playTime)
        }
      )

      this.$nextTick(() => {
        this.player.on('timeupdate', () => {
          this.timeupdate()
        })
      })
    },
    // 限制播放时间段
    timeupdate() {
      const current = this.player.getCurrentTime()
      if (this.timers) return

      this.timers = setTimeout(() => {
        if (current > this.currentVideo.end) {
          this.player.pause()
          this.player.seek(this.currentVideo.playTime)
        } else if (current < this.currentVideo.playTime) {
          this.player.seek(this.currentVideo.playTime)
        }
        clearTimeout(this.timers)
        this.timers = null
      }, 1000)
    },
    /* 推荐直播列表 */
    liveToday() {
      const data = {
        pageSize: 5,
        pageNum: 1,
        zbStartTime: this.zbStartTime,
        zbEndTime: this.zbStartTime,
        isShow: 1,
      }
      know.getPublicityClassList(data).then((res) => {
        this.recommendedLive = res.rows
      })
    },
    addStudentRecord() {
      const data = {
        classId: this.id,
        stuId: this.stuId,
        tenantId: this.tenantId,
        type: 2,
        status: this.status,
        userId: this.userId,
        time: 5,
        // time: this.time,
        terminal: 'PC',
      }
      know.addStudent(data).then((res) => { })
    },
    getTime() {
      this.time++
      this.timer = setTimeout(() => {
        if (new Date().getTime() > this.courseDetail.endTime) {
          this.addStudentRecord()
        } else {
          this.getTime()
        }
      }, 1000)
    },
    /* 推荐公开课列表 */
    getPublicityClassRecommend() {
      const data = {
        id: this.id,
      }
      know.getPublicityClassRecommend(data).then((res) => {
        this.recommendList = res.msg
      })
    },
    toDetail(item) {
      if (this.userInfo) {
        if (item.state == 2 && !item.playbackUrl) {
          this.$message({
            type: 'error',
            message: '回放生成中',
          })
        } else {
          this.addRecord(item)
          const routeUrl = this.$router.resolve({
            path: '/publicClass/publicClassDetail',
            query: {
              id: item.id,
            },
          })
          window.open(routeUrl.href, '_blank')
        }
      } else {
        Bus.$emit('toLoginPage', true)
      }
      /* if (item.state == 2 && !item.playbackUrl) {
        this.$message({
          type: "error",
          message: "回放生成中",
        });
      } else {
        this.addRecord(item);
        let routeUrl = this.$router.resolve({
          path: "/publicClass/publicClassDetail",
          query: {
            id: item.id,
          },
        });
        window.open(routeUrl.href, "_blank");
      } */
    },

    coursePublicPvSave() {
      const data = {
        courseId: this.id,
        platform: 0,
      }
      know.coursePublicPvSave(data).then((res) => { })
    },

    /* 点击公开课添加观看记录 */
    addRecord(item) {
      const data = {
        userId: this.userInfo ? this.userInfo.id : null,
        userName: this.userInfo ? this.userInfo.userName : null,
        classId: item.id,
        port: this.userInfo ? this.userInfo.userSource : null,
      }
      know.addPublicityClassStudentRecord(data).then((res) => { })
    },
    plaer() {
      const that = this
      setTimeout(() => {
        that.player = new Aliplayer(
          {
            id: 'vzanvideo',
            source: that.courseDetail.playbackUrl,
            qualitySort: 'asc',
            format: 'mp4',
            mediaType: 'video',
            width: '100%',
            height: '100%',
            autoplay: true,
            isLive: false,
            rePlay: false,
            playsinline: true,
            preload: true,
            controlBarVisibility: 'clikc',
            showBarTime: 5000,
            useH5Prism: true,
          },
          function (player) {
            player.setPreviewTime(0)
          }
        )
      }, 100)
    },

    getQRCode() {
      const data = {
        classId: this.id,
        satatus: 1,
      }
      know.asyncaddQRCodPublicityClass(data).then((res) => {
        if (res.code == 0) {
          this.QRCodeUrl = res.data.QRCodeUrl
          this.PCtext = res.data.PCtext
        }
      })
    },

    copyUrl() {
      // 模拟 输入框
      var cInput = document.createElement('input')
      cInput.value = this.PCtext
      document.body.appendChild(cInput)
      cInput.select() // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand('copy')

      this.$message({
        type: 'success',
        message: '复制成功',
      })
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput)
    },

    /* ============================网易云信=============================== */

    async joinGroup() {
      await joinGroups({
        bizGroupId: this.imQunId,
        bizUserId: this.userInfo.wxStuId,
        bizUserName: this.userInfo.userName,
      }).then(async (res1) => {

        if (res1.code == 0) {
          await getGroupChatLog({ bizGroupId: this.imQunId }).then((res2) => {
            if (res2.code == 0) {
              this.list = res2.data
              this.scrollbottom()
            }
          })
        } else {
        }
      })
    },

    // 发送信息
    async sendMessage(event) {
      event.preventDefault() // 阻止浏览器默认换行操作
      await sendMessage({
        type: 1,
        bizFrom: this.userInfo.wxStuId,
        bizTo: this.imQunId,
        text: this.barrage,
      })
      this.barrage = ''
    },

    im_connect() {
      console.log(12)
      try {
        this.count = this.count + 1
        this.im_websocket = new WebSocket(this.ws_server_url)
      } catch (evt) {
        console.log('new WebSocket error:' + evt.data)
        this.im_websocket = null
      }
      this.im_websocket.onopen = this.im_onOpen
      this.im_websocket.onclose = this.im_onClose
      this.im_websocket.onmessage = this.im_onMessage
      this.im_websocket.onerror = this.im_onError
    },
    im_onOpen(evt) {
      // console.log("Connectedss");
      // console.log(this.req);
      this.im_websocket.send(
        JSON.stringify({
          // 连接成功将，类型回传给服务端
          requestId: this.req,
          reqMsg: this.userInfo.userName,
          type: 1,
        })
      )
      this.im_time_interval = window.setInterval(this.im_send, 10000) // 开始每10s发送一次ping消息
      console.log('Connected to WebSocket server.')
    },
    im_onClose(evt) {
      // console.log("Disconnected");
    },
    im_onMessage(evt) {
      const a = JSON.parse(evt.data)

      if (a.type == 1) {
        if (a.bizToId == this.imQunId) {
          this.list.push(a)
          this.$forceUpdate()
          this.scrollbottom()
        }
      }
    },
    im_onError(evt) {
      // console.log("Error occured: " + evt.data);
    },
    im_send() {
      if (this.im_websocket.readyState == WebSocket.OPEN) {
        this.im_websocket.send(
          JSON.stringify({
            // 连接成功将，类型回传给服务端
            requestId: this.req,
            reqMsg: 'ping msg',
            type: 3,
          })
        )
      } else {
        clearInterval(this.im_time_interval)
        if (this.count < 3) {
          // 自动连接3次
          this.im_connect()
        } else {
          console.log('send failed. websocket not open. please check.')
        }
      }
    },
    // 滚动到底
    scrollbottom() {
      this.$nextTick(() => {
        const msgBlock = document.getElementById('msgBlock')
        msgBlock.scrollTop = msgBlock.scrollHeight
      })
    },
  },
}
</script>

<style lang="less" scoped>
.bigBox {
  width: 100%;
  overflow: hidden;
  position: relative;

  // margin: 0 auto;
  .share_box {
    position: absolute;
    top: 30%;
    z-index: 9999999;

    .share_btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      width: 40px;
      height: 83px;
      border-radius: 0 8px 8px 0;
      background-color: #ffffff;
      cursor: pointer;
      border: 1px solid #006cff;
      color: #006cff;
    }

    .share {
      width: 300px;
      height: 300px;
      border-radius: 8px;
      background-color: #ffffff;
      position: absolute;
      top: 0;
      right: -305px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .QRCode {
        width: 150px;
        height: 150px;
      }

      .PCtext {
        margin-top: 10px;
        width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #333333;
      }
    }
  }

  .aliVideoBar {
    width: 100%;
    height: 617px !important;
    background-color: #222222;
    padding: 32px 0;
    margin-bottom: 20px;
  }

  .videoBar {
    width: 100%;
    height: 90vh;
    background-color: #222222;
    padding: 32px 0;
    // margin-bottom: 20px;
    position: relative;

    .videoWrap {
      border: none;
      width: 1220px !important;
      height: 553px !important;
      margin: auto !important;
      display: flex;
    }

    video {
      width: 100%;
      height: 100%;
    }

    .videoBox {
      border: none;
      width: 100%;
      height: 1100px;
      margin-top: -32px;
      display: flex;
      overflow: hidden;

      .leftVideo {
        width: 880px;
        height: 553px;
        margin-right: 20px;

        .titleBox {
          height: 58px;
          border-radius: 8px 8px 0 0;
          background-color: #383838;
          padding: 19px 20px;
          color: #ffffff;
          display: flex;
          align-items: center;

          .title {
            width: 502px;
            font-size: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 50px;
          }

          .time {
            font-size: 14px;
          }
        }

        .video {
          width: 880px;
          height: 495px;
          border-radius: 0 0 8px 8px;
        }
      }

      .rightBox {
        width: 320px;
        height: 553px;

        .topTitle {
          height: 48px;
          background-color: #444444;
          border-radius: 8px 8px 0 0;
          line-height: 48px;
          color: #ffffff;
          padding-left: 21px;
        }

        .contentList {
          background-color: #383838;
          padding: 20px;
          height: 505px;
          border-radius: 0 0 8px 8px;

          .contentItem {
            height: 76px;
            width: 273px;
            display: flex;
            margin-bottom: 20px;

            .itemImg {
              width: 114px;
              height: 76px;
              border-radius: 4px;
              margin-right: 12px;
            }

            .contentTitleBox {
              width: 147px;
              font-size: 14px;
              color: #ffffff;
              line-height: 22px;
              margin-bottom: 12px;

              .contentTitle {
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                margin-bottom: 10px;
              }

              .timeItem {
                color: #999999;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .NIM_wrap {
      width: calc(20% + 25px);
      height: 90vh;
      position: absolute;
      top: 0;
      right: 0;

      .title {
        text-align: center;
        height: 44px;
        line-height: 44px;
        color: #27323f;
        font-size: 14px;
        background-color: #ffffff;
        border: 1px solid #eeeeee;
      }

      .information {
        height: calc(90vh - 44px - 40px);
        background-color: #ffffff;
        overflow: auto;
        display: flex;
        flex-direction: column;

        .white {
          font-size: 13px;
          padding: 5px;

          .wrap {
            .formNick {
              color: #333333;
              line-height: 21px;
            }

            .text {
              border-radius: 8px;
              background-color: #4b4b4b;
              padding: 12px;
              max-width: 90%;
              display: inline-block;
              word-wrap: break-word;
            }
          }
        }
      }

      .barrageInput {
        background-color: #ffffff;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .input {
          width: 78%;
        }

        .mt-10 {
          ::v-deep .icon-xiaolian {
            color: chocolate;
          }
        }
      }
    }

    .weizan {
      width: 90% !important;
      height: 90vh;
    }

    .weizans {
      width: 101% !important;
    }
  }

  .recommend {
    width: 1600px;
    margin: 0px calc(50% - 800px);
    height: 330px;
    border-radius: 8px;
    padding: 20px;
    background-color: #ffffff;
    margin: auto;

    .recommendText {
      height: 16px;
      font-size: 16px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 16px;
      margin-bottom: 30px;
    }

    .itemBox {
      height: 318px;
      display: flex;
      overflow: hidden;

      .recommendItem {
        cursor: pointer;
        width: 240px;
        height: 197px;
        margin-right: 24px;

        .imgItem {
          width: 100%;
          height: 160px;
          border-radius: 4px 4px 4px 4px;
          margin-bottom: 12px;
        }

        .textItem {
          .courseName {
            font-size: 14px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-bottom: 16px;
            line-height: 22px;
          }

          .playNum {
            display: flex;
            align-items: center;

            .symbol {
              width: 12px;
              height: 12px;
              margin-right: 4px;
            }

            .num {
              font-size: 12px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #999999;
              line-height: 12px;
            }
          }
        }
      }
    }
  }

  .tag {
    width: 52px;
    height: 20px;
    border-radius: 3px;
    padding: 0 8px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-right: 8px;
  }

  .tagBack {
    background: linear-gradient(136deg, #727a8a 0%, #494e59 100%);
  }

  .tagLive {
    background: linear-gradient(138deg, #ff9a51 0%, #ff5e51 100%);
  }

  .tagNot {
    background: linear-gradient(136deg, #727a8a 0%, #555555 100%);
  }
}

/deep/.videoBox {
  //
  width: calc(100% + 114px) !important;

  .pc-page__footer {
    color: red !important;
  }
}

.dis-start {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.dis-end {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.mainvideo {
  width: calc(80% - 25px) !important;
  height: 100%;
}

#J_prismPlayer {
  height: 100% !important;
}
</style>